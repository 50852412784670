.container {
        width: 100%
}
@media (min-width: 640px) {
        .container {
                max-width: 640px
        }
}
@media (min-width: 768px) {
        .container {
                max-width: 768px
        }
}
@media (min-width: 1024px) {
        .container {
                max-width: 1024px
        }
}
@media (min-width: 1280px) {
        .container {
                max-width: 1280px
        }
}
@media (min-width: 1536px) {
        .container {
                max-width: 1536px
        }
}
    .btn {
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: space-between;
        gap: 0.5rem;
        white-space: nowrap;
        border-radius: 9999px;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        font-size: 20px;
        transition: all 0.2s ease-in-out
}

    .btn.btn-icon {
        display: flex;
        align-items: center;
        justify-content: center
}

    .btn.btn-primary {
        --tw-bg-opacity: 1;
        background-color: rgb(255 210 51 / var(--tw-bg-opacity));
        text-transform: uppercase;
        --tw-text-opacity: 1;
        color: rgb(255 255 255 / var(--tw-text-opacity));
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
}
    .btn.btn-primary:hover {
        opacity: 0.8
}

    .btn.btn-danger {
        --tw-bg-opacity: 1;
        background-color: rgb(242 78 30 / var(--tw-bg-opacity));
        --tw-text-opacity: 1;
        color: rgb(255 255 255 / var(--tw-text-opacity))
}
    .btn.btn-danger:hover {
        opacity: 0.8
}

    .btn.btn-default {
        --tw-bg-opacity: 1;
        background-color: rgb(217 217 217 / var(--tw-bg-opacity));
        text-transform: uppercase;
        --tw-text-opacity: 1;
        color: rgb(255 255 255 / var(--tw-text-opacity));
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
}
    .btn.btn-default:hover {
        opacity: 0.8
}

    .btn:disabled,
    .btn-icon:disabled,
    .btn.active:disabled,
    .btn:hover:disabled {
        cursor: default;
        opacity: 0.4
}

.container {
    width: 100%;
}
@media (min-width: 640px) {
    .container {
        max-width: 640px;
    }
}
@media (min-width: 768px) {
    .container {
        max-width: 768px;
    }
}
@media (min-width: 1024px) {
    .container {
        max-width: 1024px;
    }
}
@media (min-width: 1280px) {
    .container {
        max-width: 1280px;
    }
}
@media (min-width: 1536px) {
    .container {
        max-width: 1536px;
    }
}
    .alert {
    position: fixed;
    z-index: 9999;
    display: flex;
    gap: 10px;
    --tw-bg-opacity: 1;
    background-color: rgb(217 217 217 / var(--tw-bg-opacity));
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
}

    .alert p {
    margin-top: 0px;
    margin-bottom: 0px;
}

    .alert button {
    cursor: pointer;
    padding: 10px !important;
    font-size: 12px;
}

    .alert.alert-primary {
    border-radius: 0px;
    --tw-border-opacity: 1;
    border-color: rgb(255 210 51 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(255 210 51 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

    .alert.alert.alert-primary:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(255 241 193 / var(--tw-bg-opacity));
}

    .alert.alert.alert-success {
    border-radius: 0px;
    --tw-border-opacity: 1;
    border-color: rgb(78 203 113 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(78 203 113 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

    .alert.alert-danger {
    border-radius: 0px;
    --tw-border-opacity: 1;
    border-color: rgb(242 78 30 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(242 78 30 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

    .alert.alert-x-center {
    left: 50%;
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

    .alert.alert-y-top {
    top: 50px;
}

.alert {
    box-shadow: var(--box-shadow);
}

.alert button{
    box-shadow: var(--box-shadow);
}

/* .alert.alert-primary:hover{
    background-color: var(--primary-hover);
} */

.alert.alert-danger:hover{
    background-color: var(--danger-hover);
}

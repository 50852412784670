.container {
        width: 100%;
}
@media (min-width: 640px) {
        .container {
                max-width: 640px;
        }
}
@media (min-width: 768px) {
        .container {
                max-width: 768px;
        }
}
@media (min-width: 1024px) {
        .container {
                max-width: 1024px;
        }
}
@media (min-width: 1280px) {
        .container {
                max-width: 1280px;
        }
}
@media (min-width: 1536px) {
        .container {
                max-width: 1536px;
        }
}
    .control {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
}
    .control label {
        font-size: 16px;
        font-weight: 700;
}
    .control input,
    .control textarea,
    .control .input {
        width: 100%;
        border-style: none;
        background-color: transparent;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        font-size: 14px;
        outline: 2px solid transparent;
        outline-offset: 2px;
}

    .control .form-group {
        display: flex;
        align-items: center;
        border-radius: 10px;
        border-width: 1px;
        --tw-border-opacity: 1;
        border-color: rgb(217 217 217 / var(--tw-border-opacity));
}

    .control .date-control {
        display: flex;
        align-items: center;
        --tw-bg-opacity: 1;
        background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

    .control .date-control .form-group {
        border-style: none;
        padding: 0px;
}

    .control .date-control .form-control {
        padding-left: 0px;
        padding-right: 0px;
        text-align: center;
}


    .control.control-date .react-datepicker__month-container {
        font-family: 'Century-Gothic';
    }
    .control.control-date .react-datepicker {
        border-radius: 20px;
        --tw-border-opacity: 1;
        border-color: rgb(255 210 51 / var(--tw-border-opacity));
}
    .control.control-date .react-datepicker__header {
        border-style: none;
        background-color: transparent;
        --tw-text-opacity: 1;
        color: rgb(0 0 0 / var(--tw-text-opacity));
}
    .control.control-date .react-datepicker__day-names {
        font-weight: 700;
}
    .control.control-date .react-datepicker__day {
        font-weight: 700;
}
    .control.control-date .react-datepicker__day.react-datepicker__day--outside-month {
        color: #00175426;
}
    .control.control-date .react-datepicker__day--keyboard-selected {
        border-width: 2px;
        --tw-border-opacity: 1;
        border-color: rgb(255 210 51 / var(--tw-border-opacity));
        background-color: transparent;
}
    .control.control-date .react-datepicker__day--selected {
        --tw-bg-opacity: 1;
        background-color: rgb(255 210 51 / var(--tw-bg-opacity));
}
    .control.control-date .react-datepicker__navigation-icon::before {
        --tw-border-opacity: 1;
        border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

    .crypto-pad .btn {
        justify-content: center;
        border-radius: 8px;
        border-width: 2px;
        --tw-border-opacity: 1;
        border-color: rgb(255 210 51 / var(--tw-border-opacity));
        padding-left: 2rem;
        padding-right: 2rem;
        padding-top: 0px;
        padding-bottom: 0px;
        font-size: 30px;
        --tw-text-opacity: 1;
        color: rgb(143 143 143 / var(--tw-text-opacity));
}
    .crypto-pad .btn img {
        max-width: none;
}

.control .se-btn-module-border {
    border: none;
}

.control .se-container {
    font-family: 'Century-Gothic';
}

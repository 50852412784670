.container {
        width: 100%
}
@media (min-width: 640px) {
        .container {
                max-width: 640px
        }
}
@media (min-width: 768px) {
        .container {
                max-width: 768px
        }
}
@media (min-width: 1024px) {
        .container {
                max-width: 1024px
        }
}
@media (min-width: 1280px) {
        .container {
                max-width: 1280px
        }
}
@media (min-width: 1536px) {
        .container {
                max-width: 1536px
        }
}
    .menu {
        display: flex;
        flex-direction: column;
        padding: 0.25rem
}
    .menu.menu-horizontal {
        flex-direction: row
}
    .menu-item a {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        transition-property: all;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;
        font-size: 14px
}
    .menu-item a .item-header {
        display: flex;
        align-items: center;
        gap: 1rem
}
    .menu-item a .item-footer {
        display: flex;
        align-items: center
}
    .menu-item a:hover,
    .menu-item a:active,
    .menu-item.active a {
        border-radius: 0.375rem;
        background-color: rgb(115 115 115 / .2);
        --tw-text-opacity: 1;
        color: rgb(255 255 255 / var(--tw-text-opacity))
}

    .menu-item a i {
        width: 1.5rem;
        text-align: center;
        font-size: 14px
}

    .menu-item a .icon-menu img {
        width: 1rem
}

    .menu-item ul {
        margin: 0px;
        padding: 0px
}
    .menu-item .item-badge {
        border-radius: 9999px;
        --tw-bg-opacity: 1;
        background-color: rgb(242 78 30 / var(--tw-bg-opacity));
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        text-align: center;
        --tw-text-opacity: 1;
        color: rgb(255 255 255 / var(--tw-text-opacity))
}

.container {
		width: 100%
}
@media (min-width: 640px) {
		.container {
				max-width: 640px
		}
}
@media (min-width: 768px) {
		.container {
				max-width: 768px
		}
}
@media (min-width: 1024px) {
		.container {
				max-width: 1024px
		}
}
@media (min-width: 1280px) {
		.container {
				max-width: 1280px
		}
}
@media (min-width: 1536px) {
		.container {
				max-width: 1536px
		}
}
    .popover-container {
		padding: 0.5rem;
		background-color: var(--white);
		border: var(--border);
		box-shadow: var(--box-shadow)
}
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    body {
        font-family: 'Century-Gothic';
    }

    h1,
    .h1 {
        @apply text-[64px] font-bold;
    }

    h2,
    .h2 {
        @apply text-[40px] font-bold;
    }

    h3,
    .h3 {
        @apply text-[36px] font-bold;
    }

    h4,
    .h4 {
        @apply text-[24px];
    }

    h5,
    .h5 {
        @apply text-[20px];
    }

    h6,
    .h6 {
        @apply text-[14px];
    }

    p,
    .p {
        @apply text-[16px] leading-[1.4em];
    }

    label,
    .label {
        @apply text-[12px] font-bold;
    }


    b, strong {
        @apply font-bold;
    }
    
    a {
        text-decoration: none;
        color: inherit;
    }
    hr {
        @apply border-default;
    }

    .override-scroll::-webkit-scrollbar {
        @apply w-2;
    }

    .override-scroll::-webkit-scrollbar-track {
        @apply bg-transparent;
    }
    
    .override-scroll::-webkit-scrollbar-thumb {
        @apply bg-transparent rounded-2xl border-[0px] border-solid bg-clip-content;
        transition: all .3s ease;
    }
    .override-scroll:hover::-webkit-scrollbar-thumb{
        @apply bg-[#444444bb];
    }
    .override-scroll::-webkit-scrollbar-thumb:hover {
        @apply bg-[#444444bb];
    }
    .text-overflow-ellipsis {
        @apply overflow-hidden text-ellipsis;
    }

}